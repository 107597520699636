
import { defineComponent } from "vue";
import Link from "@/components/Link.vue";

export default defineComponent({
  name: "Home",
  data: () => ({
    links: [
      {
        label: "Github",
        url: "https://github.com/eyzi",
        image:
          "https://cdn2.iconfinder.com/data/icons/social-aquiocons/512/Aquicon-Github.png",
      },
      {
        label: "Blogsite",
        url: "https://blog.eyzi.dev",
        image:
          "https://cdn2.iconfinder.com/data/icons/social-aquiocons/512/Aquicon-Blogger.png",
      },
    ],
  }),
  methods: {
    pronounce() {
      const audio = new Audio("/audio/pronunciation.mp3");
      audio.play();
    },
  },
  components: {
    Link,
  },
  created: () => {
    document.title = "Eyzi";
  },
});

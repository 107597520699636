
import { defineComponent } from "vue";

export default defineComponent({
  name: "HelloWorld",
  props: {
    label: String,
    url: String,
    image: String,
  },
});
